import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'
import * as flow from './flow.mjs'

import turnInCdc from './cadence/transactions/comics/turn_in_nft.cdc'
import hasCollectionCdc from './cadence/scripts/comics/has_collection.cdc'

// Transactions
export async function turnIn(comicIds, heroId) {
  var txId = await flow.sendTx({
    cadence: turnInCdc,
    args: [
      fcl.arg(comicIds, t.Array(t.UInt64)),
      fcl.arg(heroId, t.UInt64),
    ],
    payer: fcl.currentUser,
    proposer: fcl.currentUser,
    authorizations: [fcl.currentUser],
    skipSeal: true
  })
  return [txId]
}

export async function hasCollection(address) {
  return await flow.sendQuery({
    cadence: hasCollectionCdc,
    args: [fcl.arg(address, t.Address)],
  })
}
