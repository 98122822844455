import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'

import * as flow from './flow.mjs'

import mintStoreCdc from './cadence/transactions/dimensionxstore/mint_store_nft.cdc'
import getStoreStateCdc from './cadence/scripts/dimensionxstore/get_store_state.cdc'

export async function mintPackNFTs(recipient, count, setId) {
  return await flow.sendTx({
    cadence: mintStoreCdc,
    args: [fcl.arg(recipient, t.Address), fcl.arg(count, t.UInt32), fcl.arg(setId, t.UInt32)],
  })
}

export async function getStoreState() {
  return await flow.sendQuery({
    cadence: getStoreStateCdc
  })
}
