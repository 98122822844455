import config from 'global/config'
// HACK: Since Next.js doesn't have a way to run code with the server starts,
// accessing the contracts through this module ensures that flow.init is called
// with the proper environment.
export {
  comics,
  dimensionX,
  dimensionXAdmin,
} from '@crypthulhu/dmx-contracts'

import {init} from '@crypthulhu/dmx-contracts'

init(config)