import Link from 'next/link'
import * as fcl from '@onflow/fcl'
import {useAppContext} from 'context/AppContext'
import config from 'global/config'

const marketplaceHost = config.CHAIN_ENV == 'testnet' ? 'testnet.flowty.io' : 'flowty.io'
const marketplaceUrl = `https://${marketplaceHost}/collection/${config.ADMIN_ADDRESS}/DimensionX`
const inventoryUrl = (userAddress) => `https://${marketplaceHost}/user/${userAddress}?collectionFilters=%7B%22${config.ADMIN_ADDRESS}.DimensionX%22%3A%7B%7D%7D&orderFilters=%7B%7D&sort=%7B%22direction%22%3A%22desc%22%2C%22listingKind%22%3Anull%2C%22path%22%3A%22latestBlock%22%2C%22prefix%22%3A%22%22%7D&paymentTokens=null&serialFilter=%7B%22max%22%3A%22%22%2C%22min%22%3A%22%22%7D`

const NavbarLink = ({target, href, children}) => (
  <Link href={href}>
    <a
      target={target}
      className="inline-block uppercase hover:text-pink-500 hover:text-underline text-center align-middle p-2 h-auto md:p-4 mx-4 transform hover:scale-125 duration-300 ease-in-out"
    >
      {children}
    </a>
  </Link>
)

export default function Navbar() {
  var {user, hasCollection, setIsLoading} = useAppContext()

  const logIn = async () => {
    setIsLoading(true)
    try {
      const loginResponse = await fcl.authenticate()
      console.log('loginResponse', loginResponse)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const logOut = async () => {
    fcl.unauthenticate()
  }

  return (
    <div className="relative w-full font-graveside fixed z-50">
      <div className="absolute inset-0 bg-black opacity-75 -z-10"></div>
      <div className="flex items-center p-2">
        <Link href="/">
          <a className="absolute">
            <img className="w-0 md:w-40" src={`${config.WEBCDN_URL}/images/crypthulhu-logo.png`} />
          </a>
        </Link>

        <div className="md:ml-40 w-full flex justify-end">
          <NavbarLink href="/game">Play</NavbarLink>
          <NavbarLink href={marketplaceUrl}>Marketplace</NavbarLink>
          {hasCollection && <NavbarLink href={inventoryUrl(user?.addr)}>Inventory</NavbarLink>}
          {user?.loggedIn ? (
            <button className="btn" onClick={logOut}>
              LOG OUT
            </button>
          ) : (
            <button className="btn" onClick={logIn}>
              CONNECT WALLET
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
