export async function getKeyIndex(sql) {
  const [keyIndexTemp] = await sql`
    UPDATE keyvalue_store
    SET value = jsonb_set(value, '{index}', (COALESCE(value->>'index','0')::int + 1)::text::jsonb)
    WHERE key = 'flow-key-index'
    RETURNING value
  `;
  // note that we do a mod by process.env.ADMIN_ACCOUNT_TOTAL_KEYS - this is done so that we have a way to track the total number of times that we've used this function/signed things,
  // and also in case we ever decrease the number of keys for some reason, we don't have to worry about the indexes and checking if we're past the end, etc.
  let flowKeyIndexValue = keyIndexTemp?.value.index;
  let actualKeyIndex = (flowKeyIndexValue % process.env.ADMIN_ACCOUNT_TOTAL_KEYS);
  console.log(`getKeyIndex, keyIndexValue: ${flowKeyIndexValue}, actualKeyIndex: ${actualKeyIndex}`);

  return actualKeyIndex;
}

// if have 20 keys that are used in rotation for minting and unstaking, for example, this would return key index 21 - a special key that we use for one-off admin functions
// so that we never use a given key more than once at a time
// NOTE: for emulator, we always just use key at index 0 since we only have 1 key
export function getUniqueKeyIndex() {
  const keyIndex = (process.env.CHAIN_ENV === 'emulator') ? Number(0) : Number(process.env.ADMIN_ACCOUNT_TOTAL_KEYS);

  return keyIndex;
}