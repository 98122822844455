import Head from 'next/head'
import Navbar from './Navbar'
import Footer from './Footer'
import config from 'global/config'

export default function Layout({children}) {
  return (
    <div className="w-full h-full text-white"
      style={{
        backgroundImage: `url(${config.WEBCDN_URL}/images/DMX_Landing_BG.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
      }}
    >
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <div className="container md:w-3/4 h-full flex flex-col justify-between mx-auto">
        
        <Navbar />
        {children}
        <Footer />
      </div>
    </div>
  )
}
