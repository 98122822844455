import '../styles/globals.css'

import {AppContextProvider} from 'context/AppContext'
import Layout from 'components/Layout'

export default function MyApp({Component, pageProps}) {
  const getLayout = Component.getLayout || (page => <Layout>{page}</Layout>)

  return (
    <AppContextProvider>
      {getLayout(<Component {...pageProps} />)}
    </AppContextProvider>
  )
}
