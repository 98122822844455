// Function to replace import paths in cadence code with addresses from the map above
const matchImport = /^import.*from.*(?<path>\".*\/(?<contract>.*).cdc\")$/
export function replaceImports(code, addressMap) {
  return code
  .split('\n')
  .map(line => {
    try {
      let match = matchImport.exec(line)
      if (match) {
        return line.replace(
          match.groups.path,
          addressMap[match.groups.contract.toUpperCase()]
        )
      }
    } catch {}
    
    return line
  })
  .join('\n')
}

// Get an environment variable dictionary and build a map from uppercase
// contract names to addresses (e.g. THULTOKEN -> 0x2346872638472)
export function buildAddressMap(env) {
  return Object.keys(env)
    .filter(key => key.endsWith('_ADDRESS'))
    .map(key => [key.replace('_ADDRESS', ''), env[key]])
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})
}
