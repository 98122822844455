import * as fcl from '@onflow/fcl'
import * as t from '@onflow/types'

import * as flow from './flow.mjs'

import setupAccountCdc from './cadence/transactions/dimensionx/setup_account.cdc'
import hasCollectionCdc from './cadence/scripts/dimensionx/has_collection.cdc'
import getCollectionIdsCdc from './cadence/scripts/dimensionx/get_collection_ids.cdc'
import getCollectionLengthCdc from './cadence/scripts/dimensionx/get_collection_length.cdc'
import getNftMetadataCdc from './cadence/scripts/dimensionx/get_nft_metadata.cdc'
import stakeNftCdc from './cadence/transactions/dimensionx/stake_nft.cdc'
import sendTokensToTreasuryCdc from './cadence/transactions/flow_token/send_tokens_to_treasury.cdc'
import transferNftCdc from './cadence/transactions/dimensionx/transfer_nft.cdc'
import transferNftToAdminCdc from './cadence/transactions/dimensionx/transfer_to_admin.cdc'
import transferNftToAdminTestnetCdc from './cadence/transactions/dimensionx/transfer_to_admin_testnet.cdc'
import transferNftToAdmin00Cdc from './cadence/transactions/dimensionx/transfer_hardcoded_00.cdc'
import transferNftToAdmin01Cdc from './cadence/transactions/dimensionx/transfer_hardcoded_01.cdc'
import transferNftToAdmin02Cdc from './cadence/transactions/dimensionx/transfer_hardcoded_02.cdc'
import transferNftToAdmin03Cdc from './cadence/transactions/dimensionx/transfer_hardcoded_03.cdc'
import stakeAllNftCdc from './cadence/transactions/dimensionx/stake_all_nft.cdc'
import unstakeNftCdc from './cadence/transactions/dimensionx/user_unstake_nft.cdc'
import getAllStakedCdc from './cadence/scripts/dimensionx/get_all_staked_nfts.cdc'
import getCollectionStakingInfoCdc from './cadence/scripts/dimensionx/get_collection_staking_info.cdc'
import proveAccountCdc from './cadence/transactions/common/prove_account.cdc'

export {
  stakeNftCdc,
  stakeAllNftCdc,
}

// Transactions
export async function setupAccount() {
  return await flow.sendTx({
    cadence: setupAccountCdc,
    payer: fcl.currentUser,
    proposer: fcl.currentUser,
    authorizations: [fcl.currentUser],
  })
}

export async function proveAccount(OTPEncodedKey) {
  
  const results = await flow.sendTx({
    cadence: proveAccountCdc,
    args: [fcl.arg(OTPEncodedKey, t.String)],
    payer: fcl.currentUser,
    proposer: fcl.currentUser,
    authorizations: [fcl.currentUser],
    skipSeal: false,
  })
  return results
}

export async function writeDiscordHandle(DiscordHandle) {
  const txId = await flow.sendTx({
      cadence: proveAccountCdc,
      args: [
          fcl.arg(DiscordHandle, t.String)
        ],
      payer: fcl.currentUser,
      proposer: fcl.currentUser,
      authorizations: [fcl.currentUser],
      skipSeal: true
    })
   return txId
}

export async function stakeNft(id) {
  return await flow.sendTx({
    cadence: stakeNftCdc,
    args: [fcl.arg(id, t.UInt64)],
  })
}

export async function sendFlowTokensToTreasury(amount) {
  return await flow.sendTx({
    cadence: sendTokensToTreasuryCdc,
    args: [
        fcl.arg(amount, t.UFix64),
    ],
  })
}

export async function transferNft({recipient, withdrawID}) {
  console.log(recipient)
  return await flow.sendTx({
    cadence: transferNftCdc,
    args: [
        fcl.arg(recipient, t.Address),
        fcl.arg(withdrawID, t.UInt64),
    ],
  })
}

export async function transferNftToAdmin(withdrawID) {
  if(process.env.CHAIN_ENV == 'mainnet') {
    return await flow.sendTx({
      cadence: transferNftToAdminCdc,
      args: [
          fcl.arg(withdrawID, t.UInt64),
      ],
    })
  }
  else if(process.env.CHAIN_ENV == 'testnet') {
    return await flow.sendTx({
      cadence: transferNftToAdminTestnetCdc,
      args: [
          fcl.arg(withdrawID, t.UInt64),
      ],
    })
  }
}
export async function transferNftToAdmin00({withdrawID}) {
  return await flow.sendTx({
    cadence: transferNftToAdmin00Cdc,
    args: [
        fcl.arg(withdrawID, t.UInt64),
    ],
  })
}
export async function transferNftToAdmin01({withdrawID}) {
  return await flow.sendTx({
    cadence: transferNftToAdmin01Cdc,
    args: [
        fcl.arg(withdrawID, t.UInt64),
    ],
  })
}
export async function transferNftToAdmin02({withdrawID}) {
  return await flow.sendTx({
    cadence: transferNftToAdmin02Cdc,
    args: [
        fcl.arg(withdrawID, t.UInt64),
    ],
  })
}
export async function transferNftToAdmin03({withdrawID}) {
  return await flow.sendTx({
    cadence: transferNftToAdmin03Cdc,
    args: [
        fcl.arg(withdrawID, t.UInt64),
    ],
  })
}

export async function stakeAllNft() {
  return await flow.sendTx({
    cadence: stakeAllNftCdc,
  })
}
export async function unstakeNft(id) {
  return await flow.sendTx({
    cadence: unstakeNftCdc,
    args: [fcl.arg(id, t.UInt64)],
  })
}

// Scripts
export async function hasCollection(address) {
  return await flow.sendQuery({
    cadence: hasCollectionCdc,
    args: [fcl.arg(address, t.Address)],
  })
}

export async function getCollectionIds(address) {
  return await flow.sendQuery({
    cadence: getCollectionIdsCdc,
    args: [fcl.arg(address, t.Address)],
  })
}

export async function getCollectionLength(address) {
  return await flow.sendQuery({
    cadence: getCollectionLengthCdc,
    args: [fcl.arg(address, t.Address)],
  })
}

export async function getCurrentSupplyCustom() {
  return await flow.sendQuery({
    cadence: getCurrentSupplyCustomCdc
  })
}
export async function getCurrentSupplyGenesis() {
  return await flow.sendQuery({
    cadence: getCurrentSupplyGenesisCdc
  })
}
export async function getCurrentSupplyCommon() {
  return await flow.sendQuery({
    cadence: getCurrentSupplyCommonCdc
  })
}

export async function getNftMetadata({address, id}) {
  return await flow.sendQuery({
    cadence: getNftMetadataCdc,
    args: [fcl.arg(address, t.Address), fcl.arg(id, t.UInt64)],
  })
}

export async function listNfts(address) {
  var ids = await getCollectionIds(address)
  var metadatas = await Promise.all(ids.map(id => getNftMetadata(address, id)))
  return metadatas
}


export async function getAllStakedNfts() {
  return await flow.sendQuery({
    cadence: getAllStakedCdc,
  })
}

export async function getCollectionStakingInfo(address) {
  return await flow.sendQuery({
    cadence: getCollectionStakingInfoCdc,
    args: [fcl.arg(address, t.Address)],
  })
}
