import {createContext, useContext, useState, useEffect} from 'react'

import flow from 'common/flow'
import useFclCurrentUser from 'hooks/useFclCurrentUser'
import useHasCollection from 'hooks/useHasCollection'
import Lottie from 'lottie-react'
import dotsAnim from 'public/anims/dots.json'

const AppContext = createContext()

export function AppContextProvider({children}) {
  const user = useFclCurrentUser()
  const [hasCollection, checkCollection] = useHasCollection(user)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(checkCollection, [user])

  return (
    <AppContext.Provider
      value={{
        user,
        hasCollection,
        checkCollection,
        isLoading,
        setIsLoading,
      }}
    >
      <div className="w-full h-full flex">
        {children}
        {isLoading && (
          <div className="fixed z-50 h-full inset-0 bg-black opacity-75 blur blur-md flex justify-center">
            <Lottie
              style={{width:'5rem'}}
              loop
              autoPlay
              animationData={dotsAnim}
            />
          </div>
        )}
      </div>
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}
