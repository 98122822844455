import * as fcl from '@onflow/fcl'
import { ec as EC } from 'elliptic'
import { SHA3 } from 'sha3'
import { buildAddressMap, replaceImports } from './flowImports.mjs'

const ec = new EC("p256");

export var addressMap = {}

export function init(env) {
  addressMap = buildAddressMap(env)
}

// Helpers
async function sendTx(params) {
  // console.log('sendTx', params, addressMap)
  var {cadence, args, limit, skipSeal} = params
  params.cadence = replaceImports(cadence, addressMap)

  const txId = await fcl.mutate({
    limit: limit || 9999,
    ...params,
    args: (_arg, _t) => args,
  })

  if (skipSeal) return txId
  const result = await fcl.tx(txId).onceSealed()
  return [txId, result]
}

async function sendQuery({cadence, args}) {
  return await fcl.query({
    cadence: replaceImports(cadence, addressMap),
    args: (_arg, _t) => args,
  })
}

async function getLatestBlockHeight() {
  const block = await fcl.send([fcl.getBlock(true)])
  const decoded = await fcl.decode(block)
  return decoded.height
}

async function getAccount(address) {
  const {account} = await fcl.send([fcl.getAccount(address)])
  return account
}

function authorizeAdmin(actualKeyIndex) {
  return async account => {
    const user = await getAccount(process.env.ADMIN_ADDRESS)
    const keyIndex = Number(actualKeyIndex)
    const key = user.keys[keyIndex]
    const pk = process.env.ADMIN_PRIVATE_KEY

    return {
      ...account,
      tempId: `${user.address}-${key.index}`,
      addr: fcl.sansPrefix(user.address),
      keyId: Number(key.index),
      signingFunction: signable => {
        return {
          addr: fcl.withPrefix(user.address),
          keyId: Number(key.index),
          signature: sign(pk, signable.message),
        }
      },
    }
  }
}

function authorizeAdminProposer(actualKeyIndex) {
  return async account => {
    const user = await getAccount(process.env.ADMIN_ADDRESS)
    const keyIndex = Number(actualKeyIndex);
    const key = user.keys[keyIndex]
    const pk = process.env.ADMIN_PRIVATE_KEY

    return {
      ...account,
      tempId: `${user.address}-${key.index}`,
      addr: fcl.sansPrefix(user.address),
      keyId: Number(key.index),
      signingFunction: signable => {
        return {
          addr: fcl.withPrefix(user.address),
          keyId: Number(key.index),
          signature: sign(pk, signable.message),
        }
      },
    }
  }
}

function sign(privateKey, msg) {
  const key = ec.keyFromPrivate(Buffer.from(privateKey, 'hex'))
  const sig = key.sign(hashMsg(msg))
  const n = 32
  const r = sig.r.toArrayLike(Buffer, 'be', n)
  const s = sig.s.toArrayLike(Buffer, 'be', n)
  return Buffer.concat([r, s]).toString('hex')
}

function hashMsg(msg) {
  const sha = new SHA3(256)
  sha.update(Buffer.from(msg, 'hex'))
  return sha.digest()
}

export {
  sendTx,
  sendQuery,
  getLatestBlockHeight,
  getAccount,
  authorizeAdmin,
  authorizeAdminProposer,
  replaceImports,
  buildAddressMap,
}